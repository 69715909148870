import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Disclaimer.css'; // Import the CSS file for styling

const Disclaimer = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/scan'); // Change '/scan' to your next page route
  };

  return (
    <div className="disclaimer-container">
      <div className="disclaimer-box">
        <h2>⚠️ Disclaimer</h2>
        <div className="disclaimer-content">
          <p>🔍 <strong>Important</strong>: This app’s insights are for informational purposes only, demonstrating non-contact, real-time biocomputation capabilities for vital health and wellness.</p>
          <p>📈 Ongoing data collection, training, and calibration are required for each region and user. Factors such as lighting, background noise, and thick makeup may affect the results.</p>
          <p>📄 Refer to the <a href="https://pubmed.ncbi.nlm.nih.gov/38598698/" target="_blank" rel="noopener noreferrer">published research on the validation of vitals</a> for more information.</p>
        </div>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Disclaimer;
