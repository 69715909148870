import './App.css';
import { BrowserRouter as Router, Routes, Route, Link , Navigate} from "react-router-dom";
import Scan from './components/Scan';
import Disclaimer from './components/Disclaimer';
import SignIn from './components/SignIn';
function RequireAuth({children}){
  return sessionStorage.getItem("status") == 'true' ? children : <Navigate to="/" replace/>;
}
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/scan" element={
            <RequireAuth>
              <Scan />
            </RequireAuth>
          } />
          <Route path="/disclaimer" element={
            <RequireAuth>
              <Disclaimer />
            </RequireAuth>
          } />
        <Route path="/" element={<SignIn />} />
      </Routes>
      </Router>
  );
}
export default App;
